// app/components/remotion/Frames/FramesComponents/CardWithStats/CardWithStats.tsx
import React from "react";
import { Img, interpolate, spring } from "remotion";
import { Editable } from "../../../FrameContentEditors/EditableFrameWrapper";
import { isColorDark } from "../../../remotionHelpers/isColorDark";
import { FPS } from "../../../remotionConstant";
import { AnimatedCounter } from "./AnimatedCounter";

const ANIMATION = {
  CARD_START_FRAME: 0,
  LOGO_START_FRAME: 10,
  COUNTER: {
    START_FRAME: 0,
    DURATION: 50,
  },
  ARROW: {
    START_FRAME: 0,
    DURATION: 50,
  },
};

interface CardWithStatsProps {
  content: any;
  commonStyles: any;
  isEditable: boolean;
  bgColorObject: any;
  editorState: any;
  displayAnimations: boolean;
  currentRemotionFrame?: number;
}

export const CardWithStats: React.FC<CardWithStatsProps> = ({
  content,
  commonStyles,
  isEditable,
  bgColorObject,
  editorState,
  displayAnimations,
  currentRemotionFrame = 0,
}) => {
  const accentColor = editorState.styles.accentColor;
  const isDarkBg = bgColorObject["isDark"];

  const enterAnimation = spring({
    fps: FPS,
    frame: currentRemotionFrame - ANIMATION.CARD_START_FRAME,
    config: { damping: 100, stiffness: 200, mass: 0.5 },
  });

  const opacity = interpolate(enterAnimation, [0, 1], [0, 1]);
  const translateY = interpolate(enterAnimation, [0, 1], [50, 0]);
  const scale = interpolate(enterAnimation, [0, 1], [0.95, 1]);
  const shadowOpacity = interpolate(enterAnimation, [0, 1], [0, 1]);
  const shadowSize = interpolate(enterAnimation, [0, 1], [0, 14]);

  const textProgress = spring({
    fps: FPS,
    frame: currentRemotionFrame - ANIMATION.LOGO_START_FRAME,
    config: { damping: 200 },
  });

  // Arrow jump animation
  const arrowJumpProgress = spring({
    fps: FPS,
    frame: (currentRemotionFrame - ANIMATION.ARROW.START_FRAME) % ANIMATION.ARROW.DURATION,
    config: { damping: 10, stiffness: 100, mass: 0.5 },
  });

  const arrowTranslateY = interpolate(arrowJumpProgress, [0, 0.5, 1], [-10, 10, -10]);

  const rightStop = interpolate(textProgress, [0, 1], [200, 0]);
  const leftStop = Math.max(0, rightStop - 60);
  const textMaskImage = `linear-gradient(-45deg, transparent ${leftStop}%, black ${rightStop}%)`;

  // Styles
  const logoContainerStyle: React.CSSProperties = {
    position: "absolute",
    top: "-6em",
    left: "50%",
    transform: "translateX(-50%)",
    backgroundColor: isDarkBg ? "black" : "white",
    border: "0.25em solid",
    borderColor: commonStyles.color,
    borderRadius: "3em",
    padding: "1.5em 3.5em",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "10em",
    maxWidth: "20em",
    opacity: 1,
  };

  const logoStyle: React.CSSProperties = {
    maxWidth: "100%",
    height: "8em",
    width: "auto",
    objectFit: "contain",
    display: "block",
  };

  const cardStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "3em",
    padding: "8em 7em 4em",
    backgroundColor: isDarkBg ? "black" : "white",
    border: "0.25em solid",
    borderColor: commonStyles.color,
    boxShadow: displayAnimations 
      ? `${-shadowSize}px ${shadowSize}px 0 rgba(${
          commonStyles.color === "white" ? "255, 255, 255" : "0, 0, 0"
        }, ${shadowOpacity})`
      : `1.8em 1.8em 0 rgba(${
          commonStyles.color === "white" ? "255, 255, 255" : "0, 0, 0"
        }, 1)`,
    width: "max-content",
    position: "relative",
    marginTop: "6em",
    ...bgColorObject["textCss"],
    opacity: displayAnimations ? opacity : 1,
    transform: displayAnimations 
      ? `translateY(${translateY}px) scale(${scale})`
      : "none",
  };

  const numberContainerStyle: React.CSSProperties = {
    display: "flex",
    alignItems: "center",
    gap: "5em",
    marginBottom: "1em",
  };

  const percentageStyle: React.CSSProperties = {
    backgroundColor: accentColor,
    padding: "1em 3em",
    borderRadius: "2em",
    display: "flex",
    alignItems: "center",
    gap: "2em",
  };

  const titleStyle: React.CSSProperties = {
    fontSize: "7em",
    color: isDarkBg ? "white" : "black",
    maskImage: displayAnimations ? textMaskImage : undefined,
    WebkitMaskImage: displayAnimations ? textMaskImage : undefined,
  };

  const ArrowUp = () => displayAnimations ? (
    <div style={{ transform: `translateY(${arrowTranslateY}px)` }}>
      <svg 
        width="64" 
        height="64" 
        viewBox="0 0 12 12" 
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path 
          d="M6 1L6 11M6 1L11 6M6 1L1 6" 
          stroke={isColorDark(accentColor) ? "white" : "black"}
          strokeWidth="2" 
          strokeLinecap="round" 
          strokeLinejoin="round"
        />
      </svg>
    </div>
  ) : null;

  return (
    <div style={cardStyle}>
      <div style={logoContainerStyle}>
        <Editable
          isEditable={isEditable}
          content={content.projectLogo}
          hint="Project logo"
        >
          <Img
            src={content.projectLogo?.value || ""}
            style={logoStyle}
          />
        </Editable>
      </div>

      <div style={numberContainerStyle}>
        <Editable
          isEditable={isEditable}
          content={content.number1}
          hint="Main statistic number"
        >
          <AnimatedCounter
            targetNumber={content.number1?.value || "315"}
            isDarkBg={isDarkBg}
            displayAnimations={displayAnimations}
            currentRemotionFrame={currentRemotionFrame}
            ANIMATION={ANIMATION}
          />
        </Editable>

        <Editable
          isEditable={isEditable}
          content={content.number2}
          hint="Percentage change"
        >
          <div style={percentageStyle}>
            <ArrowUp />
            <span
              style={{
                fontSize: "7em",
                fontWeight: 500,
                color: isColorDark(accentColor) ? "white" : "black",
              }}
            >
              {content.number2?.value || "7%"}
            </span>
          </div>
        </Editable>
      </div>

      <Editable
        isEditable={isEditable}
        content={content.text1}
        hint="Statistic description"
      >
        <div style={titleStyle}>
          {content.text1?.value || "Total orders"}
        </div>
      </Editable>
    </div>
  );
};