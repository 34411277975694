import React from 'react';
import { Easing, interpolate } from 'remotion';
import { easeInOutExpo } from '../../../remotionConstant';

interface OrbitStrokeProps {
  displayAnimations: boolean;
  currentRemotionFrame: number;
  accentColor: string;
  radius: number;
}

export const OrbitStroke: React.FC<OrbitStrokeProps> = ({
  displayAnimations,
  currentRemotionFrame,
  accentColor,
  radius,
}) => {
  // make it smaller for the preview
  const strokeWidth = displayAnimations ? 8 : 2;
  const adjustedRadius = displayAnimations ? radius : radius/14;
  const circumference = 2 * Math.PI * adjustedRadius;
  
  const progress = displayAnimations
    ? interpolate(
        currentRemotionFrame,
        [0, 30],
        [circumference, 0],
        {
          extrapolateRight: 'clamp',
          easing: Easing.bezier(0.85, 0, 0.15, 1),
        }
    )
    : 0;

  return (
    <svg
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        opacity: 1,
      }}
      width={adjustedRadius * 2 + strokeWidth}
      height={adjustedRadius * 2 + strokeWidth}
    >
      <circle
        cx={adjustedRadius + strokeWidth/2}
        cy={adjustedRadius + strokeWidth/2}
        r={adjustedRadius}
        fill="none"
        stroke={accentColor}
        strokeWidth={strokeWidth}
        strokeDasharray={circumference}
        strokeDashoffset={progress}
        strokeLinecap="round"
      />
    </svg>
  );
}; 