import React, { useMemo } from "react";
import { AbsoluteFill, Img, interpolate } from "remotion";
import { easeInOutExpo } from "../../../remotionConstant";
import {
  EditableFrameWrapper,
} from "../../../FrameContentEditors/EditableFrameWrapper";
import { getCommonStyles } from "../../../remotionHelpers/styleUtils";
import { findBackgroundColorObjectByID } from "../../../remotionHelpers/findBackgroundColorObjectByID";
import { CardWithStats } from "./CardWithStats";

export function CardWithStatsIndex({
  frameData,
  displayAnimations = false,
  isEditable = false, // it is passed as false to the Root, but we set also false here for protection (just in case)
  editorState,
  currentRemotionFrame = 0,
}: {
  frameData: any;
  displayAnimations?: boolean;
  isEditable?: boolean;
  editorState: any;
  currentRemotionFrame?: number;
}) {
  // const { editorState } = useEditorState();
  const content = frameData.content || {};

  const { durationInFrames } = frameData;
  const commonStyles = getCommonStyles(editorState);
  const containerStyle = useMemo(
    () => ({
      fontFamily: commonStyles.fontFamily,
      color: commonStyles.color,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }),
    [commonStyles]
  );
  const bgColorObject = findBackgroundColorObjectByID(
    editorState.styles.backgroundColorID
  );
  return (
    <EditableFrameWrapper frameData={frameData} isEditable={isEditable}>
      <AbsoluteFill
        style={{
          ...containerStyle,
          backgroundColor: bgColorObject["backgroundColor"],
        }}
      >
        <CardWithStats
          content={content}
          commonStyles={commonStyles}
          isEditable={isEditable}
          bgColorObject={bgColorObject}
          editorState={editorState}
          displayAnimations={displayAnimations}
          currentRemotionFrame={currentRemotionFrame}
        />
      </AbsoluteFill>
    </EditableFrameWrapper>
  );
}
