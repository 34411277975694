import { useEffect, useMemo, useRef } from "react";
import { AbsoluteFill, interpolate } from "remotion";
import { Editable } from "../../../FrameContentEditors/EditableFrameWrapper";
import { calculateFontSize } from "../../../remotionHelpers/calculateFontSize";
import { easeInOutExpo } from "../../../remotionConstant";

export const TextTyping = ({
  currentRemotionFrame,
  content,
  displayAnimations,
  isEditable,
  bgColorObject,
  editorState,
  commonStyles
}) => {
  const frame = currentRemotionFrame;
  const typeTextRef = useRef<HTMLDivElement>(null);

  const text = content.text1?.value || "Default text";
  const words = text.split(' ');
  const wordDuration = 6;

  const accentColor = editorState.styles.accentColor;

  let textColorCss = {
    ...bgColorObject["textCss"],
  };

  const getWordAnimationStyles = (index) => {
    if (!displayAnimations) {
      return {
        opacity: 1,
        transform: 'scale(1) translateY(0)',
      };
    }
    
    const wordStartFrame = index * wordDuration;
    const wordEndFrame = wordStartFrame + 5; // 5 frames for fade in

    const opacity = interpolate(
      frame,
      [wordStartFrame, wordEndFrame],
      [0, 1],
      {
        extrapolateLeft: 'clamp',
        extrapolateRight: 'clamp'
      }
    );

    const scale = interpolate(
      frame,
      [wordStartFrame, wordEndFrame],
      [0.98, 1],
      {
        extrapolateLeft: 'clamp',
        extrapolateRight: 'clamp',
        easing: easeInOutExpo,
      }
    );

    const translateY = interpolate(
      frame,
      [wordStartFrame, wordEndFrame],
      [-5, 0],
      {
        extrapolateLeft: 'clamp',
        extrapolateRight: 'clamp',
        easing: easeInOutExpo,
      }
    );

    return {
      opacity,
      transform: `scale(${scale}) translateY(${translateY}%)`,
    };
  };

  const titleStyle: React.CSSProperties = useMemo(
    () => ({
      fontSize: `${calculateFontSize(
        content.text1?.value?.length || 0,
        [3, 8, 16, 20],
        [18, 15, 13, 10]
      )}em`,
    }),
    [commonStyles, content.text1?.value]
  );

  return (
    <AbsoluteFill
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        maxWidth: "90%",
        left: 0,
        right: 0,
        margin: "auto"
      }}
    >
      <div style={{ position: "relative" }}>
        <Editable
          isEditable={isEditable}
          content={content.text1}
          hint={"Your main message:"}
        >
          <div
            ref={typeTextRef}
            className="typeText"
            style={{
              ...titleStyle,
              lineHeight: "1.2",
              ...textColorCss,
              fontWeight: 800,
              letterSpacing: "0.01em",
              textAlign: "center",
              // padding: "0 2em",
            }}
          >
            {words.map((word, index) => (
              <span
                key={index}
                style={{
                  ...getWordAnimationStyles(index),
                  display: 'inline-block',
                  marginRight: index < words.length - 1 ? '0.25em' : 0,
                  willChange: 'transform, opacity',
                }}
              >
                {word}
              </span>
            ))}
          </div>
        </Editable>
      </div>
    </AbsoluteFill>
  );
};