import React, { useMemo } from "react";
import { AbsoluteFill, Easing, Img, interpolate } from "remotion";
import { EditableFrameWrapper, Editable } from "../../../FrameContentEditors/EditableFrameWrapper";
import { getCommonStyles } from "../../../remotionHelpers/styleUtils";
import { findBackgroundColorObjectByID } from "../../../remotionHelpers/findBackgroundColorObjectByID";
import { SearchField } from "./SearchField";
import { SearchResults } from "./SearchResults";
import { FPS, easeInOutExpo } from "../../../remotionConstant";

export function TypingSuggestionSpotlight({
  frameData,
  displayAnimations = false,
  isEditable = false,
  editorState,
  currentRemotionFrame = 0,
}: {
  frameData: any;
  displayAnimations?: boolean;
  isEditable?: boolean;
  editorState: any;
  currentRemotionFrame?: number;
}) {
  const content = frameData.content || {};
  const { durationInFrames } = frameData;
  const commonStyles = getCommonStyles(editorState);

  const containerStyle = useMemo(
    () => ({
      fontFamily: commonStyles.fontFamily,
      color: commonStyles.color,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative" as const,
    }),
    [commonStyles]
  );

  const bgColorObject = findBackgroundColorObjectByID(
    editorState.styles.backgroundColorID
  );

  const isDark = bgColorObject["isDark"];

  // Animation timing constants
  const INTRO_DURATION = 20;
  const OUTRO_DURATION = 30;
  const STEADY_STATE_DURATION = durationInFrames - (INTRO_DURATION + OUTRO_DURATION);
  
  // Calculate timing points
  const INTRO_END_FRAME = INTRO_DURATION;
  const STEADY_STATE_END_FRAME = INTRO_END_FRAME + STEADY_STATE_DURATION;

  // Initial image animations
  const initialImageOpacity = interpolate(
    currentRemotionFrame,
    [0, 20],
    [0, 1],
    {
      extrapolateRight: "clamp"
    }
  );
  
  const imageScale = interpolate(
    currentRemotionFrame,
    [0, 20],
    [1.3, 1],
    {
      extrapolateRight: "clamp",
      easing: Easing.bezier(0.16, 1, 0.3, 1),
    }
  );

  // Outro animations
  const outroOpacity = interpolate(
    currentRemotionFrame,
    [STEADY_STATE_END_FRAME, durationInFrames],
    [1, 0],
    {
      extrapolateLeft: "clamp",
      extrapolateRight: "clamp",
      easing: easeInOutExpo,
    }
  );

  // Combined opacity for the image
  const finalImageOpacity = displayAnimations 
    ? initialImageOpacity * outroOpacity 
    : 1;

  // Content movement animation - matched with FloatingCodeCard
  const contentTranslateX = interpolate(
    currentRemotionFrame,
    [0, STEADY_STATE_END_FRAME, durationInFrames],
    [0, 0, -100],
    {
      extrapolateRight: "clamp",
      easing: easeInOutExpo,
    }
  );

  const backgroundContainerStyle = {
    position: "absolute" as const,
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 0,
  };

  const BackgroundImageContent = (
    <>
      <Img
        src={content.img1.value}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          objectPosition: "center top",
          opacity: displayAnimations ? finalImageOpacity : 1,
          transform: displayAnimations ? `scale(${imageScale})` : undefined,
        }}
      />
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: isDark 
            ? "rgba(0, 0, 0, 0.88)" 
            : "rgba(255, 255, 255, 0.85)",
          zIndex: 1,
          // opacity: displayAnimations ? 1 : 1,
        }}
      />
    </>
  );

  return (
    <EditableFrameWrapper frameData={frameData} isEditable={isEditable}>
      <AbsoluteFill
        style={{
          ...containerStyle,
        }}
      >
        {/* Background Image Container */}
        {isEditable ? (
          <Editable
            content={content.img1}
            isEditable={isEditable}
            hint="Background image"
            style={{
              height: "100%",
              width: "100%"
            }}
          >
            <div style={backgroundContainerStyle}>
              {BackgroundImageContent}
            </div>
          </Editable>
        ) : (
          <div style={backgroundContainerStyle}>
            {BackgroundImageContent}
          </div>
        )}

        {/* Content Container */}
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: displayAnimations 
              ? `translate(calc(-50% + ${contentTranslateX}%), -50%)`
              : "translate(-50%, -50%)",
            zIndex: 2,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            opacity: displayAnimations ? outroOpacity : 1,
          }}
        >
          <SearchField 
            content={content} 
            isEditable={isEditable} 
            bgColorObject={bgColorObject}
            currentRemotionFrame={currentRemotionFrame}
            displayAnimations={displayAnimations}
            accentColor={editorState.styles.accentColor}
            durationInFrames={durationInFrames}
          />
          <SearchResults 
            content={content} 
            isEditable={isEditable} 
            bgColorObject={bgColorObject}
            currentRemotionFrame={currentRemotionFrame}
            displayAnimations={displayAnimations}
            durationInFrames={durationInFrames}
          />
        </div>
      </AbsoluteFill>
    </EditableFrameWrapper>
  );
}