import React from "react";
import { AbsoluteFill, interpolate, spring } from 'remotion';
import { FPS } from "../../../remotionConstant";

interface CodeBlockProps {
  code: string;
  codeRef: React.RefObject<HTMLElement>;
  bgColorObject: any;
  displayAnimations: boolean;
  currentRemotionFrame: number;
  editorState: any;
}

export const CodeBlock: React.FC<CodeBlockProps> = ({
  code,
  codeRef,
  bgColorObject,
  displayAnimations,
  currentRemotionFrame,
  editorState
}) => {
  const progress = spring({
    frame: currentRemotionFrame,
    fps: FPS,
    durationInFrames: 20,
    config: {
      damping: 200,
      stiffness: 300,
      mass: 0.5,
    },
  });

  const revealProgress = interpolate(
    progress,
    [0, 1],
    [0, 101]
  );

  const accentColor = editorState.styles.accentColor;

  return (
    <div className="code-container" style={{
      position: 'relative',
      overflow: 'hidden'
    }}>
      <pre 
        className="language-javascript" 
        style={{
          filter: (bgColorObject.isDark ? "invert(0)" : "invert(1)"),
          position: 'relative',
          margin: 0,
          zIndex: 2,
          fontSize: '2.6em',
          background: 'none',
        //   fontFamily: "monospace !important",
        }}
      >
        <code 
          ref={codeRef} 
          className="language-javascript"
        >
          {code}
        </code>
      </pre>

      {displayAnimations && (
        <AbsoluteFill style={{
          backgroundColor: bgColorObject.backgroundColor,
          borderTop: `0.4em solid ${accentColor}`,
          transform: `translateY(${revealProgress}%)`,
          zIndex: 3,
          pointerEvents: 'none'
        }} />
      )}
    </div>
  );
}; 