import React from 'react';
import { Img } from 'remotion';
import { styles } from './styles';

interface CenterLogoProps {
  src: string;
  displayAnimations?: boolean;
  currentRemotionFrame?: number;
}

export const CenterLogo: React.FC<CenterLogoProps> = ({
  src,
  displayAnimations = false,
  currentRemotionFrame = 0,
}) => {
  return src ? (
    <div style={{...styles.logoItem, ...styles.logoItemCenter}}>
      <Img src={src} style={styles.logoItemImg} />
    </div>
  ) : null;
}; 