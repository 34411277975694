import React from "react";
import { AbsoluteFill, Img, interpolate, spring } from "remotion";
import { Editable } from "../../../FrameContentEditors/EditableFrameWrapper";
import { easeInOutExpo } from "../../../remotionConstant";

// Animation constants for better control
const ANIMATION_CONSTANTS = {
  FADE_IN: {
    START_FRAME: 0,
    END_FRAME: 20,
  },
  PHASE_1: {
    START_FRAME: 0,
    END_FRAME: 60,
    START_Y: 20,
    END_Y: 0,
  },
  PHASE_2: {
    START_FRAME: 60,
    END_FRAME: 160,
    START_Y: 0,
    END_Y: -20, // in %
  },
  PHASE_3: {
    START_FRAME: 160,  // Starts right after PHASE_2
    END_FRAME: 220,    // 60 frames duration like other phases
    START_Y: -20,      // Starts where PHASE_2 ended
    END_Y: -40,        // New final position
  },
  GLARE: {
    START_FRAME: 15,
    DURATION: 60,
    MASK_START: 100,
    MASK_END: -100,
  },
  FADE_OUT: {
    START_FRAME: 200, // Starts when PHASE_2 ends
    DURATION: 30,     // Duration of fade out
  },
};

interface WebsiteScreenshotProps {
  currentRemotionFrame: number;
  content: any;
  displayAnimations: boolean;
  isEditable: boolean;
  durationInFrames: number;
}

export const WebsiteScreenshot: React.FC<WebsiteScreenshotProps> = ({
  currentRemotionFrame,
  content,
  displayAnimations,
  isEditable,
  durationInFrames
}) => {
  const fadeInProgress = interpolate(
    currentRemotionFrame,
    [ANIMATION_CONSTANTS.FADE_IN.START_FRAME, ANIMATION_CONSTANTS.FADE_IN.END_FRAME],
    [0, 1],
    {
      extrapolateLeft: "clamp",
      extrapolateRight: "clamp",
      easing: easeInOutExpo,
    }
  );

  const fadeOutProgress = displayAnimations
    ? interpolate(
        currentRemotionFrame,
        [
          ANIMATION_CONSTANTS.FADE_OUT.START_FRAME,
          ANIMATION_CONSTANTS.FADE_OUT.START_FRAME + ANIMATION_CONSTANTS.FADE_OUT.DURATION
        ],
        [1, 0],
        {
          extrapolateLeft: "clamp",
          extrapolateRight: "clamp",
          easing: easeInOutExpo,
        }
      )
    : 1;

  // 3-phase translateY animation
  const translateY = displayAnimations
  ? (() => {
      if (currentRemotionFrame <= ANIMATION_CONSTANTS.PHASE_1.END_FRAME) {
        return interpolate(
          currentRemotionFrame,
          [ANIMATION_CONSTANTS.PHASE_1.START_FRAME, ANIMATION_CONSTANTS.PHASE_1.END_FRAME],
          [ANIMATION_CONSTANTS.PHASE_1.START_Y, ANIMATION_CONSTANTS.PHASE_1.END_Y],
          {
            extrapolateLeft: "clamp",
            extrapolateRight: "clamp",
            easing: easeInOutExpo,
          }
        );
      }
      if (currentRemotionFrame <= ANIMATION_CONSTANTS.PHASE_2.END_FRAME) {
        return interpolate(
          currentRemotionFrame,
          [ANIMATION_CONSTANTS.PHASE_2.START_FRAME, ANIMATION_CONSTANTS.PHASE_2.END_FRAME],
          [ANIMATION_CONSTANTS.PHASE_2.START_Y, ANIMATION_CONSTANTS.PHASE_2.END_Y],
          {
            extrapolateLeft: "clamp",
            extrapolateRight: "clamp",
            easing: easeInOutExpo,
          }
        );
      }
      return interpolate(
        currentRemotionFrame,
        [ANIMATION_CONSTANTS.PHASE_3.START_FRAME, ANIMATION_CONSTANTS.PHASE_3.END_FRAME],
        [ANIMATION_CONSTANTS.PHASE_3.START_Y, ANIMATION_CONSTANTS.PHASE_3.END_Y],
        {
          extrapolateLeft: "clamp",
          extrapolateRight: "clamp",
          easing: easeInOutExpo,
        }
      );
    })()
  : 0;

  // Glare effect animation
  const maskPosition = displayAnimations
    ? interpolate(
        currentRemotionFrame,
        [ANIMATION_CONSTANTS.GLARE.START_FRAME, ANIMATION_CONSTANTS.GLARE.START_FRAME + ANIMATION_CONSTANTS.GLARE.DURATION],
        [ANIMATION_CONSTANTS.GLARE.MASK_START, ANIMATION_CONSTANTS.GLARE.MASK_END],
        {
          extrapolateLeft: "clamp",
          extrapolateRight: "clamp",
          easing: easeInOutExpo,
        }
      )
    : 100;

  // Add mask opacity interpolation
  const maskOpacity = displayAnimations
    ? interpolate(
        currentRemotionFrame,
        [ANIMATION_CONSTANTS.GLARE.START_FRAME + ANIMATION_CONSTANTS.GLARE.DURATION - 60, ANIMATION_CONSTANTS.GLARE.START_FRAME + ANIMATION_CONSTANTS.GLARE.DURATION],
        [0.6, 1],
        {
          extrapolateLeft: "clamp",
          extrapolateRight: "clamp",
          easing: easeInOutExpo,
        }
      )
    : 1;

  const perspective = displayAnimations
    ? interpolate(currentRemotionFrame, [0, durationInFrames], [10200, 10100], {
        extrapolateLeft: "clamp",
        extrapolateRight: "clamp",
        easing: easeInOutExpo,
      })
    : 400;

  const rotateY = displayAnimations
    ? interpolate(currentRemotionFrame, [0, durationInFrames], [20, 15], {
        extrapolateLeft: "clamp",
        extrapolateRight: "clamp",
        easing: easeInOutExpo,
      })
    : 45;

  // Combine fade-in and fade-out for final opacity
  const opacity = displayAnimations ? fadeInProgress * fadeOutProgress : 1;

  const imageStyle: React.CSSProperties = {
    width: "90%",
    height: "auto",
    transform: `perspective(${perspective}px) rotateY(${rotateY}deg) translateY(${translateY}${translateY === 0 ? 'em' : '%'})`,
    opacity,
    borderRadius: "1em",
    // transition: "transform 0.3s ease",
    WebkitMask: `linear-gradient(135deg, rgba(0,0,0,${maskOpacity}) 40%, rgba(0,0,0,1), rgba(0,0,0,${maskOpacity}) 60%) ${maskPosition}% 100% / 250% 250%`,
    mask: `linear-gradient(135deg, rgba(0,0,0,${maskOpacity}) 40%, rgba(0,0,0,1), rgba(0,0,0,${maskOpacity}) 60%) ${maskPosition}% 100% / 250% 250%`,
  };

  const containerStyle: React.CSSProperties = {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    paddingTop: "2em",
  };

  const showScreenshot = () => (
    <div style={containerStyle}>
      <Img
        src={content.img1?.value || ""}
        style={imageStyle}
      />
    </div>
  );

  return (
    <AbsoluteFill>
      {isEditable ? (
        <Editable
          isEditable={isEditable}
          content={content.img1}
          hint="Upload a full-height screenshot of your website"
        >
          {showScreenshot()}
        </Editable>
      ) : (
        showScreenshot()
      )}
    </AbsoluteFill>
  );
};