import React from "react";
import { CodeBlock } from "./CodeBlock";

interface SyntaxHighlightedCodeProps {
  code: string;
  isEditable: boolean;
  content: any;
  bgColorObject: any;
  displayAnimations: boolean;
  currentRemotionFrame: number;
  editorState: any;
}

declare global {
  interface Window {
    Prism: typeof import('prismjs');
  }
}

export const SyntaxHighlightedCode: React.FC<SyntaxHighlightedCodeProps> = React.memo(({
  code,
  isEditable,
  content,
  bgColorObject,
  displayAnimations,
  currentRemotionFrame,
  editorState
}) => {
  const codeRef = React.useRef<HTMLElement>(null);
  
  React.useEffect(() => {
    let mounted = true;
    let prismLoaded = false;
  
    const loadPrism = async () => {
      if (prismLoaded) return;
      
      try {
        const prismModule = await import('prismjs');
        window.Prism = prismModule.default;
        
        await Promise.all([
          import('prismjs/components/prism-javascript'),
          import('prismjs/themes/prism-tomorrow.css')
        ]);
  
        if (mounted) {
          prismLoaded = true;
          if (codeRef.current) {
            window.Prism.highlightElement(codeRef.current);
          }
        }
      } catch (error) {
        console.error('Failed to load Prism:', error);
      }
    };
  
    loadPrism();
  
    return () => {
      mounted = false;
    };
  }, []);
  
  React.useEffect(() => {
    if (window.Prism && codeRef.current) {
      window.Prism.highlightElement(codeRef.current);
    }
  }, [code]);

  return (
    <CodeBlock 
      bgColorObject={bgColorObject} 
      code={code} 
      codeRef={codeRef}
      displayAnimations={displayAnimations}
      currentRemotionFrame={currentRemotionFrame}
      editorState={editorState}
    />
  );
});

SyntaxHighlightedCode.displayName = 'SyntaxHighlightedCode';