// LoaderToCheck.tsx
import React from "react";
import { interpolate, spring } from "remotion";
import { FPS } from "../../../remotionConstant";

interface LoaderToCheckProps {
  currentRemotionFrame: number;
  typingStartFrame: number;
  typingEndFrame: number;
  isDark: boolean;
  accentColor: string;
}

export const LoaderToCheck: React.FC<LoaderToCheckProps> = ({
  currentRemotionFrame,
  typingStartFrame,
  typingEndFrame,
  isDark,
  accentColor,
}) => {
  const isTyping = currentRemotionFrame >= typingStartFrame && currentRemotionFrame < typingEndFrame;
  const isComplete = currentRemotionFrame >= typingEndFrame;

  // Spinning animation during typing
  const rotation = interpolate(
    currentRemotionFrame,
    [typingStartFrame, typingEndFrame],
    [0, 1080],
    {
      extrapolateRight: "clamp",
    }
  );

  // Circle animation
  const circleProgress = spring({
    fps: FPS,
    frame: Math.max(0, currentRemotionFrame - typingEndFrame),
    config: {
      damping: 200,
    },
    durationInFrames: 20,
  });

  // Checkmark animation
  const checkmarkProgress = spring({
    fps: FPS,
    frame: Math.max(0, currentRemotionFrame - typingEndFrame - 10),
    config: {
      damping: 200,
    },
    durationInFrames: 25,
  });

  const strokeColor = accentColor;
  const size = 48; // Adjust size as needed
  const strokeWidth = 5;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 100 100"
      style={{
        transform: isTyping ? `rotate(${rotation}deg)` : undefined,
        marginLeft: "12px",
        position: "absolute",
        right: "2em",
      }}
    >
      {/* Circle */}
      <circle
        cx="50"
        cy="50"
        r="46"
        fill="transparent"
        stroke={strokeColor}
        strokeWidth={strokeWidth * 2}
        strokeDasharray={289}
        strokeDashoffset={isTyping ? 200 : interpolate(circleProgress, [0, 1], [200, 0])}
        style={{ transformOrigin: "center" }}
      />

      {/* Checkmark */}
      {isComplete && (
        <polyline
          points="30,50 45,65 70,35"
          fill="transparent"
          stroke={strokeColor}
          strokeWidth={strokeWidth * 2}
          strokeDasharray={100}
          strokeDashoffset={interpolate(checkmarkProgress, [0, 1], [100, 0])}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      )}
    </svg>
  );
};