import React from 'react';
import { Img, interpolate } from 'remotion';
import { easeInOutExpo } from '../../../remotionConstant';
import { ANIMATION } from './constants';
import { styles } from './styles';

interface LogoItemProps {
  src: string;
  index?: number;
  isCenter?: boolean;
  currentRemotionFrame?: number;
  displayAnimations?: boolean;
  content?: any;
  radius?: number;
  angle?: number;
  totalLogos: number;
  bgColorObject?: any;
}

export const LogoItem: React.FC<LogoItemProps> = ({
  src,
  index = 0,
  totalLogos = 1,
  currentRemotionFrame = 0,
  displayAnimations = false,
  radius = 400,
  bgColorObject,
}) => {
  const getPosition = (): React.CSSProperties => {
    if (!displayAnimations) {
      const baseAngle = (2 * Math.PI * index) / totalLogos;
      const x = `${(radius/16) * Math.cos(baseAngle)}em`;
      const y = `${(radius/16) * Math.sin(baseAngle)}em`;
      
      return {
        position: 'absolute' as const,
        left: '50%',
        top: '50%',
        transform: `translate(-50%, -50%) translate(${x}, ${y})`,
        opacity: 1,
      };
    }

    // Initial appear animation
    const opacity = interpolate(
      currentRemotionFrame,
      [ANIMATION.INITIAL_APPEAR.START_FRAME, ANIMATION.INITIAL_APPEAR.DURATION],
      [0, 1],
      { extrapolateRight: 'clamp', easing: easeInOutExpo }
    );

    // Initial scale animation
    const scale = interpolate(
      currentRemotionFrame,
      [ANIMATION.INITIAL_APPEAR.START_FRAME, ANIMATION.INITIAL_APPEAR.DURATION],
      [ANIMATION.SCALE.MIN, ANIMATION.SCALE.MAX],
      { extrapolateRight: 'clamp', easing: easeInOutExpo }
    );

    // Orbit distance animation (moving from center to orbit)
    const orbitDistance = interpolate(
      currentRemotionFrame,
      [ANIMATION.INITIAL_APPEAR.START_FRAME, ANIMATION.INITIAL_APPEAR.DURATION],
      [ANIMATION.ORBIT.DISTANCE_SCALE.MIN, ANIMATION.ORBIT.DISTANCE_SCALE.MAX],
      { extrapolateRight: 'clamp', easing: easeInOutExpo }
    );

    // Initial quick rotation
    const initialRotation = interpolate(
      currentRemotionFrame,
      [ANIMATION.INITIAL_ROTATION.START_FRAME, ANIMATION.INITIAL_ROTATION.DURATION],
      [0, ANIMATION.INITIAL_ROTATION.DEGREES],
      { extrapolateRight: 'clamp', easing: easeInOutExpo }
    );

    // Continuous rotation - starts immediately but becomes noticeable after initial rotation
    const continuousRotation = currentRemotionFrame * ANIMATION.CONTINUOUS_ROTATION.SPEED;

    // Calculate final angle including base position and all rotations
    const baseAngle = (2 * Math.PI * index) / totalLogos;
    const totalRotation = initialRotation + continuousRotation;
    const finalAngle = baseAngle + (totalRotation * Math.PI) / 180;

    // Apply orbit distance scaling to the radius
    const finalRadius = (radius/16) * orbitDistance;
    const x = `${finalRadius * Math.cos(finalAngle)}em`;
    const y = `${finalRadius * Math.sin(finalAngle)}em`;

    return {
      position: 'absolute' as const,
      left: '50%',
      top: '50%',
      transform: `translate(-50%, -50%) translate(${x}, ${y}) scale(${scale})`,
      opacity,
    };
  };

  return (
    <div 
      style={{
        ...styles.logoItem,
        ...styles.logoItemOrbit,
        ...getPosition(),
        backgroundColor: bgColorObject?.backgroundColor || 'transparent'
      }}
    >
      <Img 
        src={src} 
        style={styles.logoItemImg}
      />
    </div>
  );
};