import React from "react";
import { interpolate } from "remotion";
import { easeInOutExpo } from "../../../remotionConstant";
import { ENDING_ANIMATION } from "./endingAnimationConstants";

interface EndingAnimationProps {
  currentRemotionFrame: number;
  durationInFrames: number;
  displayAnimations: boolean;
  accentColor: string;
}

export const EndingAnimation: React.FC<EndingAnimationProps> = ({
  currentRemotionFrame,
  durationInFrames,
  displayAnimations,
  accentColor,
}) => {
  if (!displayAnimations) return null;

  const startFrame = durationInFrames - ENDING_ANIMATION.CIRCLE.START_FRAME_FROM_END;
  const fadeOutStart = durationInFrames - ENDING_ANIMATION.FADE_OUT.START_FRAME_FROM_END;

  const circleSize = interpolate(
    currentRemotionFrame - startFrame,
    [0, ENDING_ANIMATION.CIRCLE.DURATION],
    [0, Math.max(1920, 1080) * 2],
    {
      extrapolateRight: "clamp",
      extrapolateLeft: "clamp",
      easing: easeInOutExpo,
    }
  );

  const opacity = interpolate(
    currentRemotionFrame,
    [fadeOutStart, durationInFrames],
    [1, 0],
    {
      extrapolateRight: "clamp",
      extrapolateLeft: "clamp",
      easing: easeInOutExpo,
    }
  );

  return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        width: `${circleSize/16}em`,
        height: `${circleSize/16}em`,
        borderRadius: "50%",
        backgroundColor: accentColor,
        transform: "translate(-50%, -50%)",
        opacity,
        zIndex: 1000,
      }}
    />
  );
}; 