export const styles = {
  logosCircle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    position: 'relative',
  },

  logoItem: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    padding: '2em',
  },

  logoItemImg: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },

  logoItemCenter: {
    position: 'relative',
    width: '18em',
    height: '18em',
    zIndex: 2,
  },

  logoItemOrbit: {
    width: '14em',
    height: '14em',
    transformOrigin: 'center center',
  },
} as const; 