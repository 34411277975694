// app/components/remotion/Frames/FramesComponents/CardWithStats/AnimatedCounter.tsx
import React from "react";
import { interpolate, spring } from "remotion";
import { FPS, easeInOutExpo } from "../../../remotionConstant";

interface AnimatedCounterProps {
  targetNumber: string;
  isDarkBg: boolean;
  displayAnimations: boolean;
  currentRemotionFrame: number;
  ANIMATION: {
    COUNTER: {
      START_FRAME: number;
      DURATION: number;
    };
  };
}

const DIGITS = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

export const AnimatedCounter: React.FC<AnimatedCounterProps> = ({
  targetNumber,
  isDarkBg,
  displayAnimations,
  currentRemotionFrame,
  ANIMATION,
}) => {

    // Sanitize and validate the input
  const sanitizeNumber = (input: string): string => {
    // Remove any non-digit characters
    const cleanNumber = input.replace(/[^\d]/g, '');
    // Return "0" if the input is empty or invalid
    return cleanNumber || "0";
  };

  // Parse target number and sanitize it
  const parsedNumber = sanitizeNumber(targetNumber);
  
  const containerStyle: React.CSSProperties = {
    position: "relative",
    width: `${parsedNumber.length * 7.8}em`,
    height: "14em",
    display: "flex",
    justifyContent: "flex-end",
    overflow: "hidden",
  };

  const digitContainerStyle: React.CSSProperties = {
    position: "relative",
    width: "7.8em",
    height: "100%",
    overflow: "hidden",
    display: "inline-block",
  };

  const digitStyle: React.CSSProperties = {
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "14em",
    fontWeight: 600,
    color: isDarkBg ? "white" : "black",
    fontFamily: "inherit",
    lineHeight: 1,
    userSelect: "none",
  };

  // Calculate animation progress
  const progress = spring({
    fps: FPS,
    frame: currentRemotionFrame - ANIMATION.COUNTER.START_FRAME,
    config: {
      damping: 20,
      mass: 0.5,
      stiffness: 100,
    },
    durationInFrames: ANIMATION.COUNTER.DURATION,
  });

  const renderDigit = (targetDigit: string, position: number) => {
    const targetValue = parseInt(targetDigit, 10);
    
    // Calculate the current scroll position
    const scrollProgress = interpolate(
      progress,
      [0, 1],
      [0, targetValue],
      {
        extrapolateRight: "clamp"
      }
    );

    if (!displayAnimations) {
      return (
        <div key={position} style={digitContainerStyle}>
          <div style={digitStyle}>{targetDigit}</div>
        </div>
      );
    }

    return (
      <div key={position} style={digitContainerStyle}>
        {DIGITS.map((digit, index) => {
          const digitValue = parseInt(digit, 10);
          const distance = digitValue - scrollProgress;
          
          // Calculate opacity and position for smooth rolling effect
          const opacity = interpolate(
            Math.abs(distance),
            [0, 1],
            [1, 0],
            {
              extrapolateRight: "clamp"
            }
          );

          const translateY = interpolate(
            distance,
            [-1, 0, 1],
            [100, 0, -100],
            {
              extrapolateLeft: "clamp",
              extrapolateRight: "clamp",
              easing: easeInOutExpo
            }
          );

          return (
            <div
              key={digit}
              style={{
                ...digitStyle,
                transform: `translateY(${translateY}%)`,
                opacity,
              }}
            >
              {digit}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div style={containerStyle}>
      {parsedNumber.split('').map((digit, index) => renderDigit(digit, index))}
    </div>
  );
};