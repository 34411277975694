import React from "react";
import { AbsoluteFill } from "remotion";
import { EditableFrameWrapper } from "../../../FrameContentEditors/EditableFrameWrapper";
import { getCommonStyles } from "../../../remotionHelpers/styleUtils";
import { findBackgroundColorObjectByID } from "../../../remotionHelpers/findBackgroundColorObjectByID";
import { Logos } from "./Logos";
import { EndingAnimation } from "./EndingAnimation";
export function LogosCircle({
  frameData,
  displayAnimations = false,
  isEditable = false,
  editorState,
  currentRemotionFrame = 0,
}) {
  const content = frameData.content || {};
  const commonStyles = getCommonStyles(editorState);
  const bgColorObject = findBackgroundColorObjectByID(
    editorState.styles.backgroundColorID
  );

  const containerStyle = {
    fontFamily: commonStyles.fontFamily,
    color: commonStyles.color,
    backgroundColor: bgColorObject["backgroundColor"],
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <EditableFrameWrapper frameData={frameData} isEditable={isEditable}>
      <AbsoluteFill style={containerStyle}>
        <Logos
          content={content}
          displayAnimations={displayAnimations}
          isEditable={isEditable}
          currentRemotionFrame={currentRemotionFrame}
          commonStyles={commonStyles}
          bgColorObject={bgColorObject}
          editorState={editorState}
          durationInFrames={frameData.durationInFrames}
        />
        <EndingAnimation
          currentRemotionFrame={currentRemotionFrame}
          durationInFrames={frameData.durationInFrames}
          displayAnimations={displayAnimations}
          accentColor={editorState.styles.accentColor}
        />
      </AbsoluteFill>
    </EditableFrameWrapper>
  );
}
