export const ANIMATION = {
  INITIAL_APPEAR: {
    START_FRAME: 0,
    DURATION: 30,
  },
  INITIAL_ROTATION: {
    START_FRAME: 0,
    DURATION: 30,
    DEGREES: 20,
  },
  CONTINUOUS_ROTATION: {
    SPEED: 0.1, // degrees per frame
  },
  SCALE: {
    MIN: 0.8,
    MAX: 1,
  },
  ORBIT: {
    DISTANCE_SCALE: {
      MIN: 0.5, // Start at 50% of final orbit distance
      MAX: 1,
    }
  }
}; 