import React from "react";
import { interpolate } from "remotion";

interface AnimatedCircleProps {
  index: number;
  currentFrame: number;
  accentColor: string;
  durationInFrames: number;
  circleCount?: number;
}

export const CIRCLE_COUNT = 8;

export const AnimatedCircle: React.FC<AnimatedCircleProps> = ({
  index,
  currentFrame,
  accentColor,
  durationInFrames,
  circleCount = CIRCLE_COUNT,
}) => {
  // Calculate angle offset for even distribution
  const angleOffset = (360 / circleCount) * index;
  
  // Stagger animation start for each circle
  const delay = index * 4 - 20;

  // Scale animation: from 0 to final size with index-based sizing
  const scale = interpolate(
    currentFrame - delay,
    [0, 60], // Animation happens in first 60 frames
    [0, 1 + index * 0.5], // Each circle gets progressively larger
    { 
      extrapolateRight: "clamp",
    }
  );

  // Rotation animation: fast spin initially, then slower continuous rotation
  const rotation = interpolate(
    currentFrame - delay,
    [0, 60, durationInFrames], // Three phases of rotation
    [0, 720, 360 + currentFrame * 0.1], // Initial fast spin, then continuous slower rotation
    {
      extrapolateRight: "clamp",
    }
  );

  // Opacity animation: fade in and out
  const opacity = interpolate(
    currentFrame - delay,
    [0, 30, durationInFrames - 60, durationInFrames],
    [0, 1, 1, 0], // Start transparent, become semi-transparent, fade out at end
    {
      extrapolateRight: "clamp",
    }
  );

  return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        width: `${(index + 1) * 100}px`, // Progressive size increase
        height: `${(index + 1) * 100}px`,
        border: `0.25em solid ${accentColor}`,
        borderRadius: "48%",
        transform: `translate(-50%, -50%) scale(${scale}) rotate(${
          rotation + angleOffset
        }deg)`,
        opacity,
        transition: "all 0.3s ease-out",
        pointerEvents: "none", // Prevent interference with interactions
        zIndex: 1, // Ensure circles stay behind content
      }}
    />
  );
};

// Helper function to create multiple circles
export const createAnimatedCircles = (
  displayAnimations: boolean,
  currentRemotionFrame: number,
  accentColor: string,
  durationInFrames: number,
  circleCount = CIRCLE_COUNT
) => {
  if (!displayAnimations) return null;

  return Array.from({ length: circleCount }).map((_, index) => (
    <AnimatedCircle
      key={`circle-${index}`}
      index={index}
      currentFrame={currentRemotionFrame}
      accentColor={accentColor}
      durationInFrames={durationInFrames}
      circleCount={circleCount}
    />
  ));
};