import React from 'react';
import { LogoItem } from './LogoItem';
import { Editable } from '../../../FrameContentEditors/EditableFrameWrapper';
import './LogosCircle.css';
import { CenterLogo } from './CenterLogo';
import { OrbitStroke } from './OrbitStroke';
import { interpolate } from 'remotion';
import { styles } from './styles';

export const Logos = ({
  content,
  displayAnimations = false,
  isEditable = false,
  currentRemotionFrame = 0,
  commonStyles,
  bgColorObject,
  editorState,
  durationInFrames,
}) => {
  const orbitRadius = 400;
  //make sure to only pass items with .image property to avoid errors 
  const logosList = (content.list1?.value || []).filter(logo => logo.image); 
  const centerLogo = content.projectLogo?.value;
  //get the total number of logos to orbit
  const totalLogos = logosList.length;

  const renderCenterLogo = () => (
    centerLogo && (
      <CenterLogo
        src={centerLogo}
        displayAnimations={displayAnimations}
        currentRemotionFrame={currentRemotionFrame}
      />
    )
  );

  const renderOrbitingLogos = () => (
    <>
      {logosList.map((logo, index) => (
        <LogoItem
          key={index}
          src={logo.image}
          index={index}
          content={logo}
          displayAnimations={displayAnimations}
          currentRemotionFrame={currentRemotionFrame}
          radius={orbitRadius}
          angle={(360 / totalLogos) * index}
          totalLogos={totalLogos}
          bgColorObject={bgColorObject}
        />
      ))}
    </>
  );


  const wrapperOpacity = interpolate(currentRemotionFrame, [durationInFrames - 10, durationInFrames], [1, 0]);

  return (
    <div style={styles.logosCircle}>
      <div style={{opacity: wrapperOpacity}}>
        <OrbitStroke
          displayAnimations={displayAnimations}
          currentRemotionFrame={currentRemotionFrame}
          accentColor={editorState.styles.accentColor}
          radius={orbitRadius}
        />
        <Editable
          content={content.projectLogo}
          isEditable={isEditable}
          hint="Upload your main logo"
          style={{
            zIndex: 10,
          }}
        >
          {renderCenterLogo()}
        </Editable>

        <Editable
          content={content.list1}
          isEditable={isEditable}
          hint="Add logos that will orbit around your main logo"
          style={{
            zIndex: 5,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
            height: '80%',
          }}
        >
          {renderOrbitingLogos()}
        </Editable>
      </div>
    </div>
  );
};
