// SearchField.tsx
import React from "react";
import { Img, spring, interpolate } from "remotion";
import { Editable } from "../../../FrameContentEditors/EditableFrameWrapper";
import { FPS } from "../../../remotionConstant";
import { LoaderToCheck } from "./LoaderToCheck";

interface SearchFieldProps {
  content: any;
  isEditable: boolean;
  bgColorObject: any;
  currentRemotionFrame: number;
  displayAnimations: boolean;
  accentColor: string;
  durationInFrames: number;

}

export const SearchField: React.FC<SearchFieldProps> = ({ 
  content, 
  isEditable,
  bgColorObject,
  currentRemotionFrame,
  displayAnimations,
  accentColor
}) => {
  const isDark = bgColorObject["isDark"];

  // Initial fade in
  const opacity = interpolate(
    currentRemotionFrame,
    [0, 15],
    [0, 1],
    {
      extrapolateRight: "clamp"
    }
  );

  // Typing animation with fixed duration
  const searchText = content.text1.value || "Search query text";
  const typingStartFrame = 0; // Start typing after fade in
  const typingDuration = 60; // Fixed typing duration
  const typingEndFrame = typingStartFrame + typingDuration;
  
  // Calculate current character index based on fixed duration
  const progress = Math.max(0, currentRemotionFrame - typingStartFrame) / typingDuration;
  const currentCharIndex = Math.floor(progress * searchText.length);
  
  const displayText = displayAnimations 
    ? searchText.substring(0, Math.min(currentCharIndex, searchText.length))
    : searchText;

  // Scale and position animation - starts after typing completes
  const scaleProgress = spring({
    fps: FPS,
    frame: Math.max(0, currentRemotionFrame - typingEndFrame),
    config: {
      damping: 200,
    },
    durationInFrames: 30,
  });

  const scaleValue = interpolate(
    scaleProgress,
    [0, 1],
    [1.8, 1]
  );

  const translateY = interpolate(
    scaleProgress,
    [0, 1],
    [30, 0]
  );

  // Cursor blink animation
  const showCursor = displayAnimations && 
    currentRemotionFrame > typingStartFrame && 
    currentCharIndex < searchText.length && 
    (currentRemotionFrame % FPS < FPS/2);

  return (
    <div
      style={{
        position: "relative",
        width: "60em",
        backgroundColor: isDark ? "#1A1B1E" : "white",
        borderRadius: "1.125em",
        padding: "1.5em 2.25em",
        display: "flex",
        alignItems: "center",
        boxShadow: isDark 
          ? "0 6px 9px rgba(0, 0, 0, 0.3)"
          : "0 6px 9px rgba(0, 0, 0, 0.1)",
        border: isDark 
          ? "1.5px solid rgba(255, 255, 255, 0.1)"
          : "1.5px solid rgba(0, 0, 0, 0.1)",
        opacity: displayAnimations ? opacity : 1,
        transform: displayAnimations 
          ? `scale(${scaleValue}) translateY(${translateY}%)`
          : undefined,
        transformOrigin: "center center",
      }}
    >
      <div
        style={{
        //   width: "3em",
          height: "3em",
          marginRight: "1.5em",
          flexShrink: 0,
        }}
      >
        {isEditable ? (
          <Editable 
            content={content.projectLogo} 
            isEditable={isEditable} 
            hint="Project logo. Square works the best."
            style={{
                // width: "100%",
                height: "100%",
              }}
          >
            <Img
              src={content.projectLogo.value}
              style={{
                // width: "100%",
                maxHeight: "100%",
              }}
            />
          </Editable>
        ) : (
          <Img
            src={content.projectLogo.value}
            style={{
                maxHeight: "100%",
            }}
          />
        )}
      </div>
      <Editable
        isEditable={isEditable}
        content={content.text1}
        hint="Search text"
      >
        <div
          style={{
            fontSize: "2.7em",
            color: isDark ? "#E9ECEF" : "#1a1a1a",
            fontWeight: 500,
            position: "relative",
          }}
        >
            <span style={{ opacity: 0 }}>{searchText}</span>
            <div style={{ 
              position: "absolute",
              top: 0,
              left: 0,
            }}>
              {displayText}
              {showCursor && (
                <span
                  style={{
                    position: "absolute",
                    width: "2px",
                    height: "1.2em",
                    backgroundColor: isDark ? "#E9ECEF" : "#1a1a1a",
                    marginLeft: "2px",
                  }}
                />
              )}
            </div>
        </div>
      </Editable>
        {displayAnimations && <LoaderToCheck
            currentRemotionFrame={currentRemotionFrame}
            typingStartFrame={typingStartFrame}
            typingEndFrame={typingEndFrame}
            isDark={isDark}
            accentColor={accentColor}
        />}
    </div>
  );
};