// app/components/remotion/Frames/FramesComponents/Frame6/Frame6Index.tsx

import React, { useMemo } from "react";
import { AbsoluteFill } from "remotion";
import { EditableFrameWrapper } from "../../../FrameContentEditors/EditableFrameWrapper";
import { getCommonStyles } from "../../../remotionHelpers/styleUtils";
import { findBackgroundColorObjectByID } from "../../../remotionHelpers/findBackgroundColorObjectByID";
import { FloatingCodeCard } from "./FloatingCodeCard";

export function Frame6Index({
  frameData,
  displayAnimations = false,
  isEditable = false,
  editorState,
  currentRemotionFrame = 0,
}: {
  frameData: any;
  displayAnimations?: boolean;
  isEditable?: boolean;
  editorState: any;
  currentRemotionFrame?: number;
}) {
  const content = frameData.content || {};
  const { durationInFrames } = frameData;
  const commonStyles = getCommonStyles(editorState);
  
  const containerStyle = useMemo(
    () => ({
      fontFamily: commonStyles.fontFamily,
      color: commonStyles.color,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }),
    [commonStyles]
  );

  return (
    <EditableFrameWrapper frameData={frameData} isEditable={isEditable}>
      <AbsoluteFill style={containerStyle}>
        <FloatingCodeCard
          content={content}
          displayAnimations={displayAnimations}
          isEditable={isEditable}
          currentRemotionFrame={currentRemotionFrame}
          durationInFrames={durationInFrames}
          editorState={editorState}
        />
      </AbsoluteFill>
    </EditableFrameWrapper>
  );
}