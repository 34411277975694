// app/components/remotion/Frames/FramesComponents/Frame8/Frame8Index.tsx
import React, { useMemo } from "react";
import { AbsoluteFill } from "remotion";
import { EditableFrameWrapper } from "../../../FrameContentEditors/EditableFrameWrapper";
import { getCommonStyles } from "../../../remotionHelpers/styleUtils";
import { findBackgroundColorObjectByID } from "../../../remotionHelpers/findBackgroundColorObjectByID";
import { WebsiteScreenshot } from "./WebsiteScreenshot";
export function Frame8Index({
  frameData,
  displayAnimations = false,
  isEditable = false,
  editorState,
  currentRemotionFrame = 0,
}: {
  frameData: any;
  displayAnimations?: boolean;
  isEditable?: boolean;
  editorState: any;
  currentRemotionFrame?: number;
}) {
  const content = frameData.content || {};
  const { durationInFrames } = frameData;
  const commonStyles = getCommonStyles(editorState);
  
  const containerStyle = useMemo(
    () => ({
      fontFamily: commonStyles.fontFamily,
      color: commonStyles.color,
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
    }),
    [commonStyles]
  );

  const bgColorObject = findBackgroundColorObjectByID(
    editorState.styles.backgroundColorID
  );

  return (
    <EditableFrameWrapper frameData={frameData} isEditable={isEditable}>
      <AbsoluteFill
        style={{
          ...containerStyle,
          backgroundColor: bgColorObject["backgroundColor"],
        }}
      >
        <WebsiteScreenshot
          currentRemotionFrame={currentRemotionFrame}
          content={content}
          displayAnimations={displayAnimations}
          isEditable={isEditable}
          durationInFrames={durationInFrames}
        />
      </AbsoluteFill>
    </EditableFrameWrapper>
  );
}