// app/components/remotion/state/EditorContext.tsx

import React, { ReactNode, useCallback } from "react";
import { EditorStateProvider, useEditorState } from "./EditorStateContext";
import { EditorSyncProvider } from "./EditorSyncContext";
import { allFrames } from "../../../components/remotion/Frames/allFrames";

interface EditorProviderProps {
  children: ReactNode;
}

export function EditorProvider({
  children,
}: EditorProviderProps) {
  return (
    <EditorStateProvider>
        <EditorSyncProvider>{children}</EditorSyncProvider>
    </EditorStateProvider>
  );
}

export { useEditorState } from "./EditorStateContext";
export { useEditorSync } from "./EditorSyncContext";

export function useUpdateFrameDuration() {
  const { setEditorState } = useEditorState();

  return useCallback((frameId: number, newDuration: number) => {
    setEditorState((prevState) => {
      const frames = prevState.content?.frames || [];
      const updatedFrames = frames.map((frame) => {
        if (frame.id === frameId) {
          // Get default duration from allFrames if not set
          const defaultDuration = allFrames[frame.type]?.defaultDurationInFrames || 100;
          return {
            ...frame,
            durationInFrames: Math.max(10, newDuration || defaultDuration),
          };
        }
        return frame;
      });

      return {
        ...prevState,
        content: {
          ...prevState.content,
          frames: updatedFrames,
        },
      };
    });
  }, [setEditorState]);
}
