// SearchResults.tsx
import React from "react";
import { Img, spring, interpolate } from "remotion";
import { Editable } from "../../../FrameContentEditors/EditableFrameWrapper";
import { FPS } from "../../../remotionConstant";

interface SearchResultsProps {
  content: any;
  isEditable: boolean;
  bgColorObject: any;
  currentRemotionFrame: number;
  displayAnimations: boolean;
  durationInFrames: number;

}

export const SearchResults: React.FC<SearchResultsProps> = ({ 
    content, 
    isEditable,
    bgColorObject,
    currentRemotionFrame,
    displayAnimations
  }) => {
    const isDark = bgColorObject["isDark"];
    const typingEndFrame = 70;
    const containerAnimDuration = 20;
    const itemRevealDelay = 5;
  
    // Vertical scale animation for container
    const scaleProgress = spring({
      fps: FPS,
      frame: Math.max(0, currentRemotionFrame - typingEndFrame),
      config: {
        damping: 200,
      },
      durationInFrames: containerAnimDuration,
    });
  
    const containerScaleY = interpolate(
      scaleProgress,
      [0, 1],
      [0, 1]
    );
  
    const containerOpacity = interpolate(
      scaleProgress,
      [0, 1],
      [0, 1],
      {
        extrapolateRight: "clamp"
      }
    );
  
    // Item reveal animations - start only after container animation is complete
    const getItemRevealProgress = (index: number) => {
      const containerAnimEndFrame = typingEndFrame + containerAnimDuration;
      const itemStartFrame = containerAnimEndFrame + (index * itemRevealDelay);
      
      return spring({
        fps: FPS,
        frame: Math.max(0, currentRemotionFrame - itemStartFrame),
        config: {
          damping: 200,
        },
        durationInFrames: 15,
      });
    };
  
    // Only show items after container animation is complete
    const showItems = currentRemotionFrame > (typingEndFrame + containerAnimDuration);
  
    return (
      <div
        style={{
          width: "60em",
          backgroundColor: isDark ? "#1A1B1E" : "white",
          borderRadius: "1.125em",
          marginTop: "1.5em",
          padding: "0.75em",
          boxShadow: isDark 
            ? "0 6px 9px rgba(0, 0, 0, 0.3)"
            : "0 6px 9px rgba(0, 0, 0, 0.1)",
          border: isDark 
            ? "1.5px solid rgba(255, 255, 255, 0.1)"
            : "1.5px solid rgba(0, 0, 0, 0.1)",
          opacity: displayAnimations ? containerOpacity : 1,
          transform: displayAnimations
            ? `scaleY(${containerScaleY})`
            : undefined,
          transformOrigin: "top center",
          overflow: "hidden",
        }}
      >
        <Editable
          isEditable={isEditable}
          content={content.list1}
          hint="Search results, 4-5 items"
        >
          {content.list1.value.map((item, index) => {
            if (!item || !item.text) return null;
            
            const itemProgress = displayAnimations ? getItemRevealProgress(index) : 1;
  
            return (
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "1.125em 1.5em",
                  borderRadius: "0.75em",
                  cursor: "pointer",
                  opacity: displayAnimations ? (showItems ? itemProgress : 0) : 1,
                  transform: displayAnimations && showItems
                    ? `translateY(${interpolate(itemProgress, [0, 1], [20, 0])}px)`
                    : undefined,
                }}
              >
                {(item.image && item.image !== "") ? (
                  <div
                    style={{
                      width: "3em",
                      height: "3em",
                      marginRight: "1.5em",
                      flexShrink: 0,
                    }}
                  >
                    <Img
                      src={item.image}
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    /> 
                  </div>
                ) : null}
                <div>
                  <div
                    style={{
                      fontSize: "2.4em",
                      color: isDark ? "#E9ECEF" : "#1a1a1a",
                      fontWeight: 500,
                    }}
                  >
                    {item.text}
                  </div>
                  {item.secondaryText && <div
                    style={{
                      fontSize: "1.8em",
                      color: isDark ? "#909296" : "#666",
                      marginTop: "0.3em",
                    }}
                  >
                    {item.secondaryText}
                  </div>}
                </div>
              </div>
            );
          })}
        </Editable>
      </div>
    );
  };